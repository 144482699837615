@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/roboto/Roboto-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/roboto/Roboto-Light.ttf");
  font-weight: 200;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/roboto/Roboto-Medium.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/roboto/Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/roboto/Roboto-Black.ttf");
  font-weight: 800;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
